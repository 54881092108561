import QuotationService from "../services/QuotationService";
import { types } from "../types/types";
import { setSelectedCampain } from "./summaryAction";
import { addNotificationError } from "./notificationAction";
import { getAdditionalProducts } from "./addictionalProductsAction";

export const getCampains = (obj) => {
    return async (dispatch) => {
        let quotationservice = new QuotationService();
        dispatch(setSelectedCampain(null));
        dispatch(setCampains([]));
        dispatch(setLoadingCampains(true));

        const { data, status, errors } = await quotationservice.getCampains(obj);
        dispatch(setLoadingCampains(false));
        if (status === 200) {
            data.TablaCampanias.forEach((campain) => {
                campain.EntradaInicial = campain.EntradaInicial ? campain.EntradaInicial / 100 : 0;
                campain.EntradaInicialMin = campain.EntradaInicialMin ? campain.EntradaInicialMin / 100 : 0;
                campain.EntradaInicialMax = campain.EntradaInicialMax ? campain.EntradaInicialMax / 100 : null;
            });
            dispatch(setCampains(data?.TablaCampanias ?? []));
            dispatch(setSelectedCampain(data?.TablaCampanias[0]));
            dispatch(getAdditionalProducts());
        } else {
            dispatch(addNotificationError(errors));
        }
    };
};

export const setCampains = (payload) => ({
    type: types.campainSetCampains,
    payload,
});

export const setLoadingCampains = (payload) => ({
    type: types.campainSetLoading,
    payload,
});
